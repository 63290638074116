import { stringify } from 'query-string';
import axios from 'helpers/axios';

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query1 = {
            'page': JSON.stringify(page),
            'per-page': JSON.stringify(perPage),
            'sort': (order === "DESC" ? '-' : '' )  + field,
        };
        const query = {...query1, ...params.filter};
        return axios.get(axios.addApiUrl(`/${resource}?${stringify(query)}`))
            .then((response) => ({
                data: response.data.items,
                total: response.data.meta.totalCount,
            }));
    },
    
    getOne: (resource, params) => 
        axios.get(axios.addApiUrl(`/${resource}/${params.id}`))
            .then((response) => ({
                data: response.data,
            })),
            
    getMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.get(axios.addApiUrl(`/${resource}/${id}`))
            )
        ).then(responses => ({ data: responses.map((response) => response.data) })),

    create: (resource, params) =>
        axios.post(axios.addApiUrl(`/${resource}`),params.data)
        .then((response) => ({
            data: response.data,
        }))
        .catch((error)=>{
            if (error.response.status === 422){
                let message = 'Data Validation Failed: ';
                error.response.data.map( e =>{
                    message += e.message + ' ';
                    return null;
                })
                console.log(message);
                return Promise.reject(message);
            }
            return Promise.reject(error.message);
        }),

    update: (resource, params) =>
        axios.put(axios.addApiUrl(`/${resource}/${params.id}`),params.data)
        .then((response) => ({
            data: response.data,
        }))
        .catch((error)=>{
            if (error.response.status === 422){
                let message = 'Data Validation Failed: ';
                error.response.data.map( e =>{
                    message += e.message + ' ';
                    return null;
                })
                console.log(message);
                return Promise.reject(message);
            }
            return Promise.reject(error.message);
        }),

    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.put(axios.addApiUrl(`/${resource}/${id}`),params.data)
            )
        ).then(responses => ({ data: responses.map((response) => response.data) })),

    delete: (resource, params) =>
        axios.delete(axios.addApiUrl(`/${resource}/${params.id}`))
        .then((response) => ({
            data: params.data
        })),

    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.delete(axios.addApiUrl(`/${resource}/${id}`))
            )
        ).then(responses => ({data:[]})),
};

export default dataProvider;