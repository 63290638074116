import React, {Fragment, cloneElement} from "react";
import { 
    List,
    useListContext,
    sanitizeListRestProps,
    Datagrid, 
    TextField,
    UrlField,
    ArrayField,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm, 
    TextInput,
    Filter,
    BulkDeleteButton,
    Toolbar,
    TopToolbar,
    CreateButton,
    SaveButton,
    DeleteButton,
    ShowButton,
    EditButton,
    CloneButton,
    ArrayInput,
    SimpleFormIterator,
    required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimeInput } from 'components/form/KeyboardDateTimeInput';
import RemoteSelectInput from 'components/form/RemoteSelectInput';
import RemoteAutocompleteInput from 'components/form/RemoteAutocompleteInput';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const SessionListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增"/>
    </TopToolbar>
  );
};

const SessionBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable = {false} {...props} />
  </Fragment>
);

const SessionFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="service_id"
      label="服務"
      remoteurl="/list/service"
      idproperty = "id"
      valueproperty = "abbreviation"
      alwaysOn
    />
    <RemoteSelectInput 
      source="status_code"
      label="狀態"
      remoteurl="/code/session-status"
      idproperty = "code"
      valueproperty = "name"
      alwaysOn
    />
    <RemoteAutocompleteInput
      source="worker_id"
      label="同工"
      remoteurl="/list/worker"
      idproperty = "id"
      valueproperty = "nickname"
      alwaysOn
    />
  </Filter>
);

const SessionEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton  label="儲存"/>
    <DeleteButton label="刪除" undoable = {false} />
  </Toolbar>
);

const SessionForm = (props) => (
  <SimpleForm {...props}>
    <TextInput 
      source="description"
      label="描述"
      validate={required()}
      fullWidth
    />
    <RemoteAutocompleteInput
      source="service_id"
      label="服務"
      remoteurl="/list/service"
      idproperty = "id"
      valueproperty = "abbreviation"
      validate={required()}
      fullWidth
    />
    <RemoteSelectInput 
      source="type_code"
      label="形式"
      remoteurl="/code/session-type"
      idproperty = "code"
      valueproperty = "name"
      validate={required()}
      fullWidth
    />
    <TextInput 
      source="link"
      label="網上活動連結"
      type="url"
      fullWidth
      />
    <DateTimeInput 
      source="start_datetime" 
      label="開始日期/時間"
      options={{ 
        format: 'yyyy-MM-dd HH:mm:ss',
        clearable: true,
        fullWidth: true,
      }}
      // parse = { value => {
      //   return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;
      // }}
      fullWidth
    />
    <DateTimeInput 
      source="end_datetime"
      label="結束日期/時間"
      options={{ 
        format: 'yyyy-MM-dd HH:mm:ss',
        clearable: true,
        fullWidth: true,
      }}
      // parse = { value => {
      //   return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;
      // }}
      fullWidth
    />
    <RemoteSelectInput 
      source="status_code"
      label="狀態"
      remoteurl="/code/session-status"
      idproperty = "code"
      valueproperty = "name"
      validate={required()}
      fullWidth
    />
    <TextInput 
      source="target" 
      label="對象"
      fullWidth
    />
    <TextInput 
      source="notes"
      label="備註(公開)"
      multiline
      fullWidth
    />
    <TextInput 
      source="remarks"
      label="備註(內部)" 
      multiline
      fullWidth
      />
    <ArrayInput 
      source="groups"
      label="分組"
      fullWidth
    >
      <SimpleFormIterator>
        <RemoteAutocompleteInput
          source="id"
          label="分組"
          remoteurl="/list/group"
          idproperty = "id"
          valueproperty = "displayname"
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput 
      source="workers"
      label="同工"
      fullWidth
    >
      <SimpleFormIterator>
        <RemoteAutocompleteInput
          source="id"
          label="同工"
          remoteurl="/list/worker"
          idproperty = "id"
          valueproperty = "nickname"
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)

export const SessionList = (props) => (
  <List {...props} 
    filters={<SessionFilter />}
    actions={<SessionListActions/>}
    bulkActionButtons={<SessionBulkActionButtons />}
  >
    <Datagrid>
      <TextField source="service.abbreviation" label="服務"/>
      <TextField source="description" label="描述"/>
      <TextField source="start_datetime" label="開始日期/時間"/>
      <TextField source="end_datetime" label="結束日期/時間"/>
      <TextField source="type.name" label="形式"/>
      <TextField source="status.name" label="狀態"/>
      <ShowButton label="詳情"/>
      <EditButton label="編輯"/>
      <CloneButton label="複製"/>
    </Datagrid>
  </List>
);

export const SessionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="description" label="描述"/>
      <TextField source="service.abbreviation" label="服務"/>
      <TextField source="type.name" label="形式"/>
      <UrlField source="link" label="網上活動連結" />
      <TextField source="start_datetime" label="開始日期/時間"/>
      <TextField source="end_datetime" label="結束日期/時間"/>
      <TextField source="status.name" label="狀態"/>
      <TextField source="target" label="對象"/>
      <TextField source="notes" label="備註(公開)" component="pre"/>
      <TextField source="remarks" label="備註(內部)" component="pre"/>
      <ArrayField source="groups" label="分組" >
        <Datagrid >
          <TextField source="displayname" label="分組" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="workers" label="同工" >
        <Datagrid >
          <TextField source="nickname" label="同工" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const SessionCreate = (props) => (
  <Create {...props}>
    <SessionForm />
  </Create>
);

export const SessionEdit = (props) => (
  <Edit {...props} undoable = {false} >
    <SessionForm toolbar={<SessionEditToolbar />} />
  </Edit>
);