import React, {Fragment, cloneElement} from "react";
import { 
    List,
    useListContext,
    sanitizeListRestProps,
    Datagrid, 
    TextField,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm, 
    TextInput,
    Filter,
    BulkDeleteButton,
    Toolbar,
    TopToolbar,
    CreateButton,
    SaveButton,
    DeleteButton,
    ShowButton,
    EditButton,
    CloneButton,
    required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoteAutocompleteInput from 'components/form/RemoteAutocompleteInput';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const ServiceListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增"/>
    </TopToolbar>
  );
};

const ServiceBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable = {false} {...props} />
  </Fragment>
);

const ServiceFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="school_id"
      label="學校"
      remoteurl="/list/school"
      idproperty = "id"
      valueproperty = "abbreviation"
      validate={required()}
      alwaysOn
    />
  </Filter>
);

const ServiceEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton  label="儲存"/>
    <DeleteButton label="刪除" undoable = {false} />
  </Toolbar>
);

const ServiceForm = (props) => (
  <SimpleForm {...props}>
    <TextInput 
      source="description"
      label="描述"
      validate={required()}
      fullWidth
    />
    <TextInput 
      source="abbreviation"
      label="簡稱"
      validate={required()}
      fullWidth
    />
    <RemoteAutocompleteInput
      source="school_id"
      label="學校"
      remoteurl="/list/school"
      idproperty = "id"
      valueproperty = "abbreviation"
      validate={required()}
      fullWidth
    />
  </SimpleForm>
)

export const ServiceList = (props) => (
  <List {...props} 
    filters={<ServiceFilter />}
    actions={<ServiceListActions/>}
    bulkActionButtons={<ServiceBulkActionButtons />}
  >
    <Datagrid>
      <TextField source="school.abbreviation" label="學校"/>
      <TextField source="description" label="描述"/>
      <TextField source="abbreviation" label="簡稱"/>
      <ShowButton label="詳情"/>
      <EditButton label="編輯"/>
      <CloneButton label="複製"/>
    </Datagrid>
  </List>
);

export const ServiceShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="description" label="描述"/>
      <TextField source="abbreviation" label="簡稱"/>
      <TextField source="school.abbreviation" label="學校"/>
    </SimpleShowLayout>
  </Show>
);

export const ServiceCreate = (props) => (
  <Create {...props}>
    <ServiceForm />
  </Create>
);

export const ServiceEdit = (props) => (
  <Edit {...props} undoable = {false} >
    <ServiceForm toolbar={<ServiceEditToolbar />} />
  </Edit>
);