import React, {Fragment, cloneElement} from "react";
import Config from 'Config';
import { 
    List,
    useListContext,
    useShowController,
    sanitizeListRestProps,
    Datagrid, 
    TextField,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm, 
    TextInput,
    Filter,
    BulkDeleteButton,
    Toolbar,
    TopToolbar,
    CreateButton,
    SaveButton,
    DeleteButton,
    ShowButton,
    EditButton,
    CloneButton,
    required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import RemoteAutocompleteInput from 'components/form/RemoteAutocompleteInput';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const GroupListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="新增"/>
    </TopToolbar>
  );
};

const GroupBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton undoable = {false} {...props} />
  </Fragment>
);

const GroupFilter = (props) => (
  <Filter {...props}>
    <RemoteAutocompleteInput
      source="service_id"
      label="服務"
      remoteurl="/list/service"
      idproperty = "id"
      valueproperty = "abbreviation"
      validate={required()}
      alwaysOn
    />
  </Filter>
);

const GroupEditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton  label="儲存"/>
    <DeleteButton label="刪除" undoable = {false} />
  </Toolbar>
);

const GroupForm = (props) => (
  <SimpleForm {...props}>
    <TextInput 
      source="description"
      label="描述"
      validate={required()}
      fullWidth
    />
    <RemoteAutocompleteInput
      source="service_id"
      label="服務"
      remoteurl="/list/service"
      idproperty = "id"
      valueproperty = "abbreviation"
      validate={required()}
      fullWidth
    />
    <TextInput 
      source="token"
      label="Token"
      fullWidth
    /> 
  </SimpleForm>
)

export const GroupList = (props) => (
  <List {...props} 
    filters={<GroupFilter />}
    actions={<GroupListActions/>}
    bulkActionButtons={<GroupBulkActionButtons />}
  >
    <Datagrid>
      <TextField source="service.abbreviation" label="服務"/>
      <TextField source="description" label="描述"/>
      <TextField source="token" label="Token"/>
      <ShowButton label="詳情"/>
      <EditButton label="編輯"/>
      <CloneButton label="複製"/>
    </Datagrid>
  </List>
);

export const GroupShow = (props) => {
  const { record } = useShowController(props);
  return(
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="description" label="描述"/>
      <TextField source="service.abbreviation" label="服務"/>
      <TextField source="token" label="Token" />
      { record.token ?
        <>
          <Typography>&nbsp;</Typography>
          <Typography variant="body2">小組連結</Typography>
          <a href={`${Config.publicHost}/s/${record.token}`} target="blank">
            {`${Config.publicHost}/s/${record.token}`}
          </a>
          <Typography>&nbsp;</Typography>
          <QRCode value={`${Config.publicHost}/s/${record.token}`}  />
        </>
      : 
        null
      }
    </SimpleShowLayout>
  </Show>
)};

export const GroupCreate = (props) => (
  <Create {...props}>
    <GroupForm />
  </Create>
);

export const GroupEdit = (props) => (
  <Edit {...props} undoable = {false} >
    <GroupForm toolbar={<GroupEditToolbar />} />
  </Edit>
);