import React, { useState } from "react";
import { useLogin, useAuthState, useNotify } from "react-admin";
import GoogleLogin from 'react-google-login';
import {
  Grid,
  TextField,
	Button,
	Typography,
	CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import Config from 'Config';
import axios from 'helpers/axios';
import FruitfulLogo from 'resources/fl-logo.png';

const styles = theme => ({
	gutterBottom: {
		marginBottom: theme.spacing(),
	},
	heading: {
    padding: theme.spacing(2),
		textAlign: 'center',
	},
	logoWrapper: {
		width: 300,
		textAlign: 'center',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: '#dee2e6',
	},
	loginBox: {
		width: 300,
		flex: 1,
		paddingBottom: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: '#dee2e6',
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: '100%',
	},  
	googleLoginBox: {
		textAlign: 'center',
	},
	googleLoginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		height: 40,
		width: '100%',
		backgroundColor: '#d14836',
		borderRadius: 4,
		color: '#FFF',
		fontSize: 14,
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
});

const LoginPage = ({...props}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");  
  const login = useLogin();
  const notify = useNotify();
	const { loading, authenticated } = useAuthState();
	const { classes, history } = props;
  const submit = e => {
		e.preventDefault();
		login({ username, password })
		.catch( e => {console.log("Invalid email or password")});
  };
  const handleLoginJwt = response => {		
		if (!response.error){
			axios.post(axios.addApiUrl('/account/login-jwt'), {jwt:response.tokenId})
			.then(response => {
				localStorage.setItem('ffl-omg-admin-auth', JSON.stringify(response.data));
				history.push('/');
			})
			.catch(e => {
				if (e.response && e.response.status === 422){
					notify(e.response.data[0].message);
          alert(e.response.data[0].message);
        }
			})
		} else {
      console.log(response);
      alert("Failed to connect Google OAuth.");
    }
  }

	if (loading) {
		return <CircularProgress />;
	}
	if (authenticated) {
		history.push('/');
	} 
  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item xs={12}>
        <div className={classes.logoWrapper}>
            <img src={FruitfulLogo} width="300px" alt="Fruitlife Training Workshop Limited" className={classes.logo} />
        </div>
        <Typography variant="h5" className={classes.heading}>活動資訊系統(後台)</Typography>
        <div className={classes.loginBox}>          
            <form onSubmit = {submit}>
            <TextField
                name="username"
                label="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                className={classes.gutterBottom}
                fullWidth
                required
            />
            <TextField
                name="password"
                label="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className={classes.gutterBottom}
                type="password"
                fullWidth
                required
            />
            <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={classes.loginButton}
            >
                Login
            </Button>
            </form>
            <div className={classes.googleLoginBox}>
              <Typography color="inherit">- or -</Typography>
              <GoogleLogin
                clientId={Config.googleClientId}
                responseType="id_token"
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                  <button 
                    onClick={renderProps.onClick}
                    className = {classes.googleLoginButton} 
                    disabled={renderProps.disabled}
                  >
                      Login with Google
                  </button>
                )}
                onSuccess={handleLoginJwt}
                onFailure={handleLoginJwt}
              />
            </div>            
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LoginPage);