import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from 'providers/authProvider';
import dataProvider from 'providers/dataProvider';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import LoginPage from 'components/LoginPage';
import { SessionList, SessionShow, SessionCreate, SessionEdit } from 'views/sessions';
import { GroupList, GroupShow, GroupCreate, GroupEdit } from 'views/groups';
import { SchoolList, SchoolShow, SchoolCreate, SchoolEdit } from 'views/schools';
import { ServiceList, ServiceShow, ServiceCreate, ServiceEdit } from 'views/services';
import { WorkerList, WorkerShow, WorkerCreate, WorkerEdit } from 'views/workers';

const App = () => (
  <Admin 
    loginPage={LoginPage} 
    authProvider={authProvider} 
    dataProvider={dataProvider}
  >
    <Resource
      name="schools"
      list={SchoolList}
      show={SchoolShow}
      create={SchoolCreate}
      edit={SchoolEdit}
      icon={LocationCityIcon}
      options={{
        label: "學校"
      }}
    />
    <Resource
      name="services"
      list={ServiceList}
      show={ServiceShow}
      create={ServiceCreate}
      edit={ServiceEdit}
      icon={MonetizationOnIcon}
      options={{
        label: "服務"
      }}
    />
    <Resource
      name="groups"
      list={GroupList}
      show={GroupShow}
      create={GroupCreate}
      edit={GroupEdit}
      icon={GroupWorkIcon}
      options={{
        label: "小組"
      }}
    />
    <Resource
      name="workers"
      list={WorkerList}
      show={WorkerShow}
      create={WorkerCreate}
      edit={WorkerEdit}
      icon={AssignmentIndIcon}
      options={{
        label: "同工"
      }}
    />
    <Resource 
      name="sessions"
      list={SessionList}
      show={SessionShow}
      create={SessionCreate}
      edit={SessionEdit}
      icon={SentimentSatisfiedAltIcon}
      options={{
        label: "活動"
      }}
    />
  </Admin>
);

export default App;